import nookies from 'nookies'

import {PATH} from 'utils/constants'


export const setCookie = (name: string, value: string = '') => {

  nookies.set(
    {}, 
    name, 
    typeof value === 'string' ? value : JSON.stringify(value), 
    {
      path: PATH.HOME,
      maxAge: value ? undefined : 0
    }
  )
}

export const getCookie = (name: string): string | undefined => {
  
  return nookies.get(null)[name]
}
