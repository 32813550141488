import router from 'next/router'
import {
  linkWithPopup, 
  signInWithPopup, 
  EmailAuthProvider,
  GoogleAuthProvider, 
  linkWithCredential,
  signInWithCustomToken,
  signOut as _signOut,
  applyActionCode as _applyActionCode,
  onIdTokenChanged as _onIdTokenChanged,
  signInAnonymously as _signInAnonymously,
  confirmPasswordReset as _confirmPasswordReset,
  sendEmailVerification as _sendEmailVerification,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  createUserWithEmailAndPassword as _createUserWithEmailAndPassword
} from 'firebase/auth'

import {auth} from 'utils/firebase'
import {COOKIE, PATH, API_PATH, DB} from 'utils/constants'
import {setCookie, insideStoreModal, closeModal, postMessage, replaceRoute, callAPI, sendReactGAEvent, offData, deleteData, informOtherTabsAboutAuth} from '.'


export const getCurrentUser = (): typeof auth.currentUser => {

  return auth?.currentUser
}

export const signOut = async () => {

  // clear cookies
  setCookie(COOKIE.ID_TOKEN)
  setCookie(COOKIE.REFRESH_TOKEN)
  setCookie(COOKIE.RECOMMENDATION)

  // clear the same data in client store local storage
  postMessage({clearData: true})
  
  // sign out
  await _signOut(auth)

  // inform other tabs about signout
  informOtherTabsAboutAuth()

  // handle sign out completion
  if (insideStoreModal()) {
    closeModal()
  } else {
    replaceRoute(PATH.HOME)
  }
}

export const signInWithUid = async (uid: string) => {

  const data = await callAPI(API_PATH.CUSTOM_TOKEN, {uid})

  if (data?.customToken) {
    signInWithCustomToken(auth, data.customToken)
  }
}

export const signInWithGoogle = (language: string) => {
  
  const google = new GoogleAuthProvider()
  auth.languageCode = language
  signInWithPopup(auth, google).catch(console.error)
}

export const signInAnonymously = (): ReturnType<typeof _signInAnonymously> => {

  return _signInAnonymously(auth)
}

export const signInWithEmailAndPassword = (email: string, password: string): ReturnType<typeof _signInWithEmailAndPassword> => {
  
  return _signInWithEmailAndPassword(auth, email, password)
}

export const confirmPasswordReset = (oobCode: string, newPassword: string): ReturnType<typeof _confirmPasswordReset> => {
  
  return _confirmPasswordReset(auth, oobCode, newPassword)
}

export const signUpWithGoogle = (language: string, callback: (user: typeof auth.currentUser) => void) => {

  const google = new GoogleAuthProvider()
  auth.languageCode = language
  linkWithPopup(auth.currentUser, google)
    .then(({user}) => {
      sendReactGAEvent({category: 'USER_SIGNUP', action: 'USER_SIGNUP_4_GOOGLE'})
      callback(user)
    })
    .catch(error => console.error(error))
}

export const deleteAuthUser = () => {

  const user = auth.currentUser
  
  if (user) {

    offData(`users/${user.uid}`)

    deleteData(`${DB.USERS}/${user.uid}`)
    .then(() => user.delete())
    .then(() => signOut())
    .catch(console.error)
  }
}

export const sendEmailVerification = (): ReturnType<typeof _sendEmailVerification> => {

  if (auth.currentUser) {
    auth.languageCode = router.locale
    try {
      return _sendEmailVerification(auth.currentUser)
    } catch (error) {
      console.error(error)
      return Promise.reject()
    }
  } else {
    return Promise.reject()
  }
}

export const sendPasswordResetEmail = (email: string): ReturnType<typeof _sendPasswordResetEmail> => {

  return _sendPasswordResetEmail(auth, email)
}

export const applyActionCode = (oobCode: string): ReturnType<typeof _applyActionCode> => {

  return _applyActionCode(auth, oobCode)
}

export const onIdTokenChanged = (callback: Parameters<typeof _onIdTokenChanged>[1]): ReturnType<typeof _onIdTokenChanged> => {
  
  return _onIdTokenChanged(auth, callback)
}

export const createUserWithEmailAndPassword = (email: string, password: string): ReturnType<typeof _createUserWithEmailAndPassword> => {
  
  return _createUserWithEmailAndPassword(auth, email, password)
}

export const completeSignupWithEmailAndPassword = (email: string, password: string) => {
  
  const credential = EmailAuthProvider.credential(email, password)
  
  return linkWithCredential(getCurrentUser(), credential)
}
