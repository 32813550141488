import router from 'next/router'
import {BroadcastChannel} from 'broadcast-channel'

import {insideStoreModal} from './other'
import {BROADCAST_CHANNEL} from 'utils/constants'
import {BroadcastChannelMessage} from 'utils/types'


export const informOtherTabsAboutAuth = (uid: string = null, idToken: string = null, refreshToken: string = null, user: boolean = false) => {

  try { // Safari doesn't support native BroadcastChannel yet
    const channel: BroadcastChannel<BroadcastChannelMessage> = new BroadcastChannel(BROADCAST_CHANNEL)
    channel.postMessage({
      auth: {
        uid,
        user, // means a completed user is available, i.e. signed in or completed registration
        path: router.pathname,
        tokens: {idToken, refreshToken},
        fromStoreModal: insideStoreModal()
      }
    })
  } catch (error) {
    console.error(error)
  }
}

export const listenForAuthOnOtherTabs = (callback: (message: BroadcastChannelMessage['auth']) => void): BroadcastChannel => {

  let channel: BroadcastChannel
  
  const handler = (message: BroadcastChannelMessage) => {

    if (message.auth) {

      callback(message.auth)
    }
  }

  try { // Safari doesn't support native BroadcastChannel yet...
    channel = new BroadcastChannel(BROADCAST_CHANNEL)
    channel.addEventListener('message', handler)
  } catch (error) {
    console.error(error)
  }

  return channel
}
