import {initializeApp, getApps} from 'firebase/app'
import {getAuth, connectAuthEmulator} from 'firebase/auth'
import {getDatabase, connectDatabaseEmulator} from 'firebase/database'
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions'


let auth: ReturnType<typeof getAuth>
let db: ReturnType<typeof getDatabase>
let functions: ReturnType<typeof getFunctions>

if (typeof window !== 'undefined') { // code running client-side

  try {
    
    const app = getApps()[0] || initializeApp({
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL
    })

    auth = getAuth(app)
    db = getDatabase(app)
    functions = getFunctions(app)
  
    if (process.env.NEXT_PUBLIC_DEV) {
      const emulators = require('../firebase.json').emulators
      connectDatabaseEmulator(db, 'localhost', emulators.database.port)
      connectFunctionsEmulator(functions, 'localhost', emulators.functions.port)
      connectAuthEmulator(auth, `http://localhost:${emulators.auth.port}/`, {disableWarnings: true})
    }

  } catch (error) {
    
    // safari forbids iframes to access indexedDB, which firebase uses - this is to catch those cases and stop the app from crashing for users
    console.error(error)
  }
}

export {db, auth, functions}
