import router from 'next/router'

import {getRoute} from '.'
import {PATH} from 'utils/constants'


export const getLanguage = (): string => {

  return router.locale
}

export const replaceRoute = (path: string) => {

  if (router.pathname === PATH.BANNER) { // never redirect /banner
    return
  }

  const pathname = getRoute(router.pathname, path)

  if (router.pathname !== pathname) {
    router.replace(pathname)
  }
}
