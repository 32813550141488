import useTranslation from 'next-translate/useTranslation'
import {get, set, ref, off, update, remove, onValue} from 'firebase/database'

import {db} from 'utils/firebase'
import {DB} from 'utils/constants'
import {updateShoesSizes} from '.'
import {ShoesSizes, ShoesSizesCustomClient, User} from 'utils/types'


export {serverTimestamp} from 'firebase/database'

export const onData = (path: string, callback: (data: any) => void, errorCallback?: (error: Error) => void) => {

  return onValue(ref(db, path), snapshot => callback(snapshot.val()), errorCallback)
}

export const offData = (path: string) => {
  
  return off(ref(db, path))
}

export const getData = async (path: string): Promise<any> => {
  
  const snapshot = await get(ref(db, path))

  return snapshot.val()
}

export const updateData = async (path: string, data: any): Promise<any> => {
  
  return update(ref(db, path), data).catch(console.error)
}

export const setData = async (path: string, data: any): Promise<any> => {
  
  return set(ref(db, path), data).catch(console.error)
}

export const deleteData = async (path: string): Promise<any> => {
  
  return remove(ref(db, path)).catch(console.error)
}


export const getShoesSizes = (callback: (shoesSizes: ShoesSizes) => void) => {

  getData(`/${DB.SHOES._}/${DB.SHOES.SIZES}/`).then(callback)
}

export const getShoesSizesForUser = ({shoes, section}: User, callback: (shoesSizes: ShoesSizes) => void, t: ReturnType<typeof useTranslation>['t']) => {

  getShoesSizes((shoesSizes: ShoesSizes) => {

    if (shoes && section) {
      shoes.forEach(shoe => {
        const {brand, type, line} = shoe
        if (
          !Object.keys(shoesSizes[section] || {}).includes(brand) || 
          !Object.keys(shoesSizes[section][brand].types[type] || {}).includes(type) || 
          !Object.keys(shoesSizes[section][brand].types[type].lines || {}).includes(line)
        ) {
          updateShoesSizes(shoesSizes, section, shoe, t)
        }
      })
    }

    callback(shoesSizes)
  })
}

export const getShoesSizesForClient = (clientId: string, callback: (shoesSizes: ShoesSizes) => void) => {

  getShoesSizes((shoesSizes: ShoesSizes) => {

    getData(`${DB.SHOES._}/${DB.SHOES.SIZES_CUSTOM_CLIENTS}/${clientId}`).then((sizes: ShoesSizesCustomClient) => {
      
      for (const section in sizes) {
        for (const brand in sizes[section]) {
          for (const type in sizes[section][brand]) {
            for (const line in sizes[section][brand][type]) {

              shoesSizes[section][brand] = shoesSizes[section][brand] || {
                diff: shoesSizes[section][brand].diff || 0, 
                types: shoesSizes[section][brand].types || {}
              }

              shoesSizes[section][brand].types[type] = shoesSizes[section][brand].types[type] || {
                diff: shoesSizes[section][brand].types[type].diff || 0,
                lines: shoesSizes[section][brand].types[type].lines || {},
                primaryLocale: shoesSizes[section][brand].types[type].primaryLocale || shoesSizes[section][brand].types[DB.ALL].primaryLocale
              }

              shoesSizes[section][brand].types[type].lines[line] = shoesSizes[section][brand].types[type].lines[line] || {
                diff: shoesSizes[section][brand].types[type].lines[line].diff || 0
              }

              shoesSizes[section][brand].types[type].lines[DB.ALL] = shoesSizes[section][brand].types[type].lines[DB.ALL] || {
                diff: shoesSizes[section][brand].types[type].lines[DB.ALL].diff || 0
              }
            }
          }
        }
      }

      callback(shoesSizes)
    })
  })
}
