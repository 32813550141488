import {httpsCallable} from 'firebase/functions'
import {functions} from 'utils/firebase'


export const callCloudFunction = (functionName: string, data: any): Promise<any> => {

  const send = httpsCallable(functions, functionName)

  return send(data).catch(console.error)
}
