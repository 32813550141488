import useTranslation from 'next-translate/useTranslation'

import {I18N} from 'utils/constants'


const UNAVAILABLE = '__unavailable'

export const isUnavailable = (size: string = ''): boolean => {

  return size.includes(UNAVAILABLE)
}

export const handleUnavailable = (size: string = '', t: ReturnType<typeof useTranslation>['t']): string => {

  return size.replace(UNAVAILABLE, t(`${I18N.BANNER}:sizeUnavailable`))
}
