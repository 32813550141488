import {PATH} from 'utils/constants'


export const toTitleCase = (phrase: string = ''): string => {
  
  return phrase
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ')
}

export const convertSizeValueToFloat = (sizeValue: string): number => {
    
  // possible size formats are 38, 38.5, 38-39, 38-39.5, 38.5-39.5, 38 1/2, 38/39, M3, M3|W4
  sizeValue.replace(',','.') // e.g. 5,5 -> 5.5 - just in case

  if (sizeValue.indexOf('|') > -1) { // e.g. M3|W4
    return Number(sizeValue.match(/\d+/)?.[0] as string) // only use the first integer
  }
  
  else if (sizeValue.indexOf(' ') > -1) { // e.g. 38 1/2
    const sizes = sizeValue.split(' ')
    const base = Number(sizes[0])
    const fraction = sizes[1]
    const fractionElements = fraction.split('/')
    const decimal = Number(fractionElements[0]) / Number(fractionElements[1])
    return base + decimal
  }

  else if (sizeValue.indexOf('/') > -1) { // e.g. 38/39
    return getAverage(sizeValue)
  }

  else if (sizeValue.indexOf('-') > -1) { // e.g. 38-39 or 38.5-39.5 or similar
    return getAverage(sizeValue)
  }

  else { // e.g. 38 or 38.5 or M11
    return Number(sizeValue.replace(/[^\d.-]/g, '')) // remove all letters
  }

  function getAverage(connectedNumbers: string): number {

    const numbers = connectedNumbers.indexOf('/') >= 0
      ? connectedNumbers.split('/')
      : connectedNumbers.split('-')

    const sum = numbers.map(number => Number(number)).reduce((a, b) => a + b)
    const count = numbers.length

    return sum / count
  }
}

export const getRoute = (from: string, to: string): string => {

  switch(to) {

    case PATH.USER_HOME_SHOES:
    case PATH.USER_HOME_PROFILE:
    case PATH.USER_HOME_MEASUREMENT:
      return from.includes(PATH.USER_HOME)
        ? from
        : to
    
    case PATH.CLIENT_HOME_TEAM:
    case PATH.CLIENT_HOME_DOCS:
    case PATH.CLIENT_HOME_SIZES:
    case PATH.CLIENT_HOME_STATS:
    case PATH.CLIENT_HOME_PROFILE:
      return from.includes(PATH.CLIENT_SIGN_OUT)
        ? PATH.CLIENT_SIGN_OUT
        : from.includes(PATH.CLIENT_HOME)
          ? from
          : to

    default:
      return to
  }
}
