import useTranslation from 'next-translate/useTranslation'

import {DB, I18N, SIZES} from 'utils/constants'
import {LineLocales, LocaleSizes, ShoesSizes} from 'utils/types'


// UTILS

export const isClientSide = (): boolean => {

  return typeof window !== 'undefined'
}

export const isEmail = (email: string): boolean => {

  if (email) {
    const emailExp = /\S+@\S+\.\S+/
    return emailExp.test(email)
  }

  return false
}

export const isModalShowing = (): boolean => {

  return isClientSide() && document.getElementById('rs_modal').hasChildNodes()
}

export const insideStoreModal = (): boolean => {

  if (isClientSide()) {
    // @ts-ignore
    if (window.Cypress) {
      return Boolean(window.parent.document.getElementById('rightsize-modal'))
    } else {
      return self !== top
    }
  }
}

export const socialLoginAvailable = (): boolean => {

  if (!isClientSide()) {
    return true
  }

  try {
    window.localStorage // this call will throw an exception where third party access to cookies and web storage is unavailable
    return true
  } catch (error) {
    return false
  }
}

export const toSentenceCase = (string: string) => {
  
  return string.charAt(0).toUpperCase() + string.substring(1)
}

export const postMessage = (message: object) => {
  
  window.parent.postMessage(
    message,
    '*' // (new URL(document.referrer)).origin
  )
}

export const closeModal = () => {

  postMessage({closeModal: true})
}

export const getHostname = (url: string): string => {

  return (new URL(url)).hostname
}

export const sortByProperty = (array: any[], property: string): any[] => {

  return array.sort((a, b) => a[property].toLowerCase().localeCompare(b[property].toLowerCase()))
}

export const sortObjectByKey = (unsorted: object): any => {

  return Object.keys(unsorted).sort().reduce(
    (obj, key) => {
      obj[key] = unsorted[key]
      return obj
    },
    {}
  )
}

export const copyObject = (obj: object): any => {

  return JSON.parse(JSON.stringify(obj))
}


// API

export const callAPI = async (path: string, data: object): Promise<any> => {

  return fetch(path, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  })
  .then(response => {
    if (response.ok) {
      return response.json()
    }
  })
  .then(data => data)
  .catch(error => console.error(error))
}


// SIZES

export const updateShoesSizes = (shoesSizes: ShoesSizes, section: string, {brand, type = DB.ALL, line = DB.ALL, brandCustom, typeCustom, lineCustom}: {brand: string, type: string, line: string, brandCustom?: string, typeCustom?: string, lineCustom?: string}, t: ReturnType<typeof useTranslation>['t']): ShoesSizes => {

  const eu = {} as LocaleSizes
  const uk = {} as LocaleSizes
  const us = {} as LocaleSizes
  const sml = {} as LocaleSizes

  SIZES.EU.forEach((size, index) => eu[index] = size)
  SIZES.US_UK.forEach((size, index) => uk[index] = size)
  SIZES.US_UK.forEach((size, index) => us[index] = size)
  SIZES.SML.forEach((size, index) => sml[index] = size)

  shoesSizes[section][brand] = shoesSizes[section][brand] || {diff: 0, types: {}, displayName: brandCustom || brand}
  shoesSizes[section][brand].types[type] = shoesSizes[section][brand].types[type] || {diff: 0, lines: {}, displayName: typeCustom || type}
  shoesSizes[section][brand].types[type].lines[line] = shoesSizes[section][brand].types[type].lines[line] || {diff: 0, displayName: lineCustom || (line === DB.ALL ? t(`${I18N.USER}:shoe.lineAll`) : line), locales: shoesSizes[section][brand].types[type].lines[DB.ALL]?.locales || shoesSizes[section][brand].types[DB.ALL]?.lines[DB.ALL]?.locales || {eu, uk, us, sml}}

  return shoesSizes
}

export const getLineLocales = (shoesSizes: ShoesSizes, section: string, {brand, type = DB.ALL, line = DB.ALL}: {brand: string, type: string, line: string}): LineLocales => {

  return (
    shoesSizes[section][brand]?.types[type]?.lines[line]?.locales ||
    shoesSizes[section][brand]?.types[type]?.lines[DB.ALL]?.locales || 
    shoesSizes[section][brand]?.types[DB.ALL]?.lines[DB.ALL]?.locales
    || {}
  )
}

export const getNormalizedSize = (size: string): number => {

  return Number(size.replace('_','.'))
}
